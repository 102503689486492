.work-enter li {
    opacity: 0;
    transform: translateX(-50px);
}

.work-enter-active li {
    opacity: 1;
    transform: translateX(0);
    transition-property: all;
    transition-duration: 300ms;
}

.work-enter-active li:nth-child(1) { transition-delay: 0ms; }
.work-enter-active li:nth-child(2) { transition-delay: 100ms; }
.work-enter-active li:nth-child(3) { transition-delay: 200ms; }
.work-enter-active li:nth-child(4) { transition-delay: 300ms; }
.work-enter-active li:nth-child(5) { transition-delay: 400ms; }
.work-enter-active li:nth-child(6) { transition-delay: 500ms; }
.work-enter-active li:nth-child(7) { transition-delay: 600ms; }
.work-enter-active li:nth-child(8) { transition-delay: 700ms; }

.work-exit {
    opacity: 1;
}

.work-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}