.workDetailGrid {
    transition: background-color ease 1000ms;
    padding-bottom: 130px;
}

.workDetailImage {
    margin: 70px 20px 0 20px;
}

.workDetailContent {
    margin: 0 20px;
}

.workDetailControls {
    position: fixed;
    left: 50%;
    bottom: 70px;
    transform: translateX(-50%);
}

.workDetailControls button {
    background: rgba(0,0,0,0.5);
}

.workDetailControls button:hover {
    background: rgba(0,0,0,0.7);
}

@media (max-width: 1023px) {
    .workDetailGrid {
        grid-template-rows: auto 1fr;
    }

    .workDetailImage {
        height: clamp(150px, 40vw, 300px);
    }
}

@media (min-width: 640px) {
    .workDescription {
        width: 80%;
    }

    .workDetailContent {
        margin: 0;
    }
}

@media (min-width: 1024px) {
    .workDetailGrid {
        padding-bottom: 0;
    }

    .workDetailImage {
        margin: 70px 20px 90px 0;
    }

    .workDetailControls {
        position: absolute;
        right: 0;
        left: auto;
        bottom: auto;
        transform: translateX(0.5rem);
    }

    .workDetailControls button {
        display: block;
    }

    .work-detail-page-enter-done .workDetailControls button {
        transition: transform ease 300ms;
    }

    .workDetailControls button:hover {
        transform: translateX(-0.5rem);
    }

    .workDetailContent {
        padding-right: 40px;
    }

    .workDescription {
        margin-bottom: 0;
    }
}





/* Page enter animations */

.work-detail-page-enter .workDescription {
    opacity: 0;
    transform: translateY(50px);
}

.work-detail-page-enter-active .workDescription {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms 300ms;
}

.work-detail-page-enter .workDetailGrid {
    opacity: 0;
}

.work-detail-page-enter-active .workDetailGrid {
    opacity: 1;
    transition: opacity 500ms;
}

/* Button animations */

@media (min-width: 1024px) {
    .work-detail-page-enter .workDetailControls button {
        opacity: 0;
        transform: translateX(100%);
    }
    
    .work-detail-page-enter-active .workDetailControls button {
        opacity: 1;
        transform: translateX(0);
        transition: all 200ms;
    }
    
    .work-detail-page-enter-active .workDetailControls button:first-child {
        transition-delay: 700ms;
    }
    
    .work-detail-page-enter-active .workDetailControls button:last-child {
        transition-delay: 800ms;
    }
}

/* Page exit animations */

.work-detail-page-exit .workDetailImage {
    opacity: 1;
    transform: translateX(0);
}

.work-detail-page-exit-active .workDetailImage {
    opacity: 0;
    transform: translateX(100%);
    transition: all 500ms;
}

.work-detail-page-exit .workDetailContent {
    opacity: 1;
}

.work-detail-page-exit-active .workDetailContent {
    opacity: 0;
    transition: opacity 500ms;
}

.work-detail-page-enter .workDetailImage {
    opacity: 0;
    transform: translateX(100%);
}

.work-detail-page-enter-active .workDetailImage {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms;
}

/* Description animation */

.work-detail-enter .workDescription {
    opacity: 0;
}

.work-detail-enter-active[data-animate-direction] .workDescription {
    opacity: 1;
    transition: all ease 500ms;
}

.work-detail-exit .workDescription {
    opacity: 1;
}

.work-detail-exit-active .workDescription {
    opacity: 0;
    transition: all ease 500ms;
}

@media(min-width: 640px) {
    .work-detail-enter .workDescription {
        opacity: 0;
    }
    
    .work-detail-enter[data-animate-direction="next"] .workDescription {
        transform: translateY(50px);
    }
    
    .work-detail-enter[data-animate-direction="prev"] .workDescription {
        transform: translateY(-50px);
    }
    
    .work-detail-enter-active[data-animate-direction] .workDescription {
        transform: translateY(0);
    }
    
    .work-detail-exit .workDescription {
        transform: translateY(0);
    }
    
    .work-detail-exit-active[data-animate-direction="next"] .workDescription {
        transform: translateY(-50px);
    }
    
    .work-detail-exit-active[data-animate-direction="prev"] .workDescription {
        transform: translateY(50px);
    }
}

/* Image transition */

.workImage {
    overflow: hidden;
}

.work-detail-exit .workImage {
    opacity: 1;
}

.work-detail-exit-active .workImage {
    opacity: 0;
    transition: opacity 500ms ease-out;
}