@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  height: 100%;
}

.noise {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.noise:after {
  content: '';
  position: absolute;
  left: -10rem;
  top: -10rem;
  animation: noise_animation 1.00s steps(2) infinite;
  will-change: transform;
  background-image: url('images/noise.png');
  background-position: 50%;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
}

@keyframes noise_animation {
  0%{transform:translate3d(0,9rem,0)}
  10%{transform:translate3d(-1rem,-4rem,0)}
  20%{transform:translate3d(-8rem,2rem,0)}
  30%{transform:translate3d(9rem,-9rem,0)}
  40%{transform:translate3d(-2rem,7rem,0)}
  50%{transform:translate3d(-9rem,-4rem,0)}
  60%{transform:translate3d(2rem,6rem,0)}
  70%{transform:translate3d(7rem,-8rem,0)}
  80%{transform:translate3d(-9rem,1rem,0)}
  90%{transform:translate3d(6rem,-5rem,0)}
  to{transform:translate3d(-7rem,0,0)}
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms, transform 1000ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}